<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="back"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <div class="oa-createCaseInfo">
      <div class="applicant-user">
        <div class="user">
          <div class="content-header">{{ sliceName(this.nickname) }}</div>
          <span>{{ this.nickname }}</span>
        </div>
        <div>{{ this.case.createTimeText }}</div>
        <div class="status">{{ caseStatusNameFmt(this.case.progress) }}</div>
      </div>
      <div class="oa-createCaseInfo-line"></div>
      <div class="case">
        <div class="title">{{ this.case.caseName }}</div>
        <div class="caseNo">{{ this.case.caseNo }}</div>
        <div class="accept-time">
          <div class="accept-time-title">收案时间</div>
          <div class="accept-time-content">{{ this.case.useTime }}</div>
        </div>
        <div class="accept-time">
          <div class="accept-time-title">诉讼标的</div>
          <div class="accept-time-content">无</div>
        </div>
      </div>
      <div class="oa-createCaseInfo-line"></div>
      <div class="oa-approval">
        <div class="oa-approval-title" style="margin-bottom:20px">审批记录</div>
        <approval-record
          :typeId="Number(this.id)"
          :type="'LEGAL_CASE_ADD'"
          ref="approvalRecord"
          @nickname="getnickname"
        />
      </div>
      <operate-btn
        v-if="operateBtnShow"
        :isAdd="1"
        :progress="this.case.progress"
        :approveRecordUsers="this.case.approveRecordUsers"
        @save="save"
      />
      <!-- <div
        class="oa-createCaseInfo-bottom"
        v-if="this.case.progress !== 'APPROVED'"
      >
        <button
          class="oa-button-save"
          @click="save('UNSUBMENT')"
          v-if="this.case.progress === 'UNSUBMENT'"
        >
          保存
        </button>
        <button
          class="oa-button-apply"
          @click="save('UNAUDITED')"
          v-if="
            this.case.progress === 'UNSUBMENT' ||
              this.case.progress === 'CERTIFIED_FAILED'
          "
        >
          申请
        </button>
        <button
          class="oa-button-delete"
          @click="save('DELETE')"
          v-if="
            this.case.progress === 'UNSUBMENT' ||
              this.case.progress === 'CERTIFIED_FAILED'
          "
        >
          删除
        </button>
        <button
          class="oa-button-save"
          @click="save('CERTIFIED_FAILED')"
          v-if="
            this.case.progress === 'UNAUDITED' && this.case.btnList.length > 0
          "
        >
          驳回
        </button>
        <button
          class="oa-button-apply"
          @click="save('APPROVED')"
          v-if="
            this.case.progress === 'UNAUDITED' && this.case.btnList.length > 0
          "
        >
          批准
        </button>
      </div> -->
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'

import ApprovalRecord from '../../components/approvalRecord'
import OperateBtn from '../../components/operateBtn'
import { mapState } from 'vuex'

export default {
  name: 'oa-createCaseInfo',
  mixins: [goBackMixin],

  components: {
    ApprovalRecord,
    OperateBtn
  },
  data() {
    return {
      nickname: '',
      title: '立案申请',
      case: {},
      id: 0,
      operateBtnShow: false
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    getnickname(name) {
      this.nickname = name.userName
    },
    sliceName(userName) {
      return userName.slice(userName.length - 2)
    },
    back() {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      const tag = diffValue > 0 ? '前' : '前'
      diffValue = Math.abs(diffValue)
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天' + tag
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时' + tag
      } else {
        result = '' + parseInt(minC) + '分钟' + tag
      }
      return result
    },
    // 案件状态格式化
    caseStatusNameFmt(code) {
      const dict = {
        UNSUBMENT: '未提交',
        UNAUDITED: '待审核',
        APPROVED: '审核通过',
        CERTIFIED_FAILED: '审核不通过'
      }
      return dict[code]
    },
    // 按钮事件
    save(progress) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (progress === 'DELETE') {
        this.deleteCase()
      } else {
        // 修改
        this.updateCase(progress)
      }
    },
    updateCase(progress) {
      const tempUser = []
      var approveRecordUsers = this.case.approveRecordUsers
      for (let i = 0; i < approveRecordUsers.length; i++) {
        if (approveRecordUsers[i].type === 'APPLICANT') {
          tempUser.push(approveRecordUsers[i])
          break
        }
      }
      tempUser.push({
        type: 'APPROVER',
        user: {
          id: this.userInfo.id
        }
      })
      for (let i = 0; i < approveRecordUsers.length; i++) {
        if (approveRecordUsers[i].type === 'RECIPIENT') {
          tempUser.push(approveRecordUsers[i])
          break
        }
      }
      this.case.approveRecordUsers = tempUser
      this.$axios
        .put(
          `${this.$base}/management/legal/case/record/${
            this.case.caseId
          }/progress`,
          {
            ...this.case,
            progress: progress
          }
        )
        .then(res => {
          const { code } = res.data
          if (code === 200) {
            this.$toast.success('操作成功')
            setTimeout(() => {
              this.back() // 返回上一层
            }, 1000)
          }
        })
    },
    deleteCase() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.$base}/management/legal/case/record/delete/${
              this.case.caseId
            }?userId=${this.userInfo.id}`
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            setTimeout(() => {
              this.back() // 返回上一层
            }, 1000)
          }
        })
        .catch(() => {
          // on cancel
        })
    }
  },
  async created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.id = this.$route.query.commonId
    const res = await this.$axios.get(
      `${this.$base}/management/legal/case/record/${
        this.$route.query.caseId
      }/caseId`
    )
    const { code, data } = res.data
    if (code === 200) {
      // 获取申请人昵称和头像
      let avatarUrl = ''
      let nickname = ''
      if (data.approveRecordUsers) {
        data.approveRecordUsers.map((item, i) => {
          if (item.type === 'APPLICANT') {
            avatarUrl = item.user.avatarUrl
            nickname = item.user.nickName
          }
        })
        data.avatarUrl = avatarUrl
        data.nickname = nickname
      }
      // 格式化申请时间和收案时间
      const acceptCaseTime = parseInt(Number(data.acceptCaseTime))
      const createTime = parseInt(Number(data.createTime))
      data.useTime = this.timestampToTime(acceptCaseTime)
      data.createTimeText = this.getDateDiff(createTime)
      console.log(
        this.timestampToTime(createTime),
        '============> 格式化申请时间和收案时间'
      )
    } else {
      this.$notify('找不到数据')
    }
    this.case = data
    this.operateBtnShow = true
    this.$toast.clear()
    // this.case = JSON.parse(this.$route.query.case)
  },
  mounted() {}
}
</script>

<style lang="stylus">
.oa-createCaseInfo
  position relative
  & .oa-createCaseInfo-line
    width 100%
    height 10px
    background rgba(242, 242, 242, 1)
  & .applicant-user
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    font-size 14px
    padding 10px 20px
    box-sizing border-box
    & .user
      display flex
      flex-direction row
      align-items center
      & .content-header
        width 35px
        height 35px
        border-radius 20px
        background #008AFF
        text-align center
        line-height 35px
        color #FFF
        margin-right 10px
      img
        width 20px
        height 20px
        border-radius 50%
        margin-right 10px
    & .status
      color #FF9900
  .case
    padding 10px 20px
    box-sizing border-box
    font-size 14px
    & .title
      font-size 16px
      font-weight 700
    & .caseNo
      color #999999
      font-size 14px
      margin-top 10px
    & .accept-time
      display flex
      flex-direction row
      align-items center
      margin-top 10px
      & .accept-time-title
        color #999999
        margin-right 20px
      & .accept-time-content
        flex 1
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 1
        overflow hidden
  & .oa-approval
    padding 10px 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
  & .oa-createCaseInfo-bottom
    width 100%
    background #FFFFFF
    position fixed
    bottom 0px
    right 0px
    padding 10px 20px
    box-sizing border-box
    display flex
    flex-direction row
    justify-content flex-end
    button
      width 80px
      height 50px
      border-radius 5px
      font-size 16px
    & .oa-button-save
      background none
      border none
      color #0079FE
    & .oa-button-apply
      background #0079FE
      border none
      color #FFFFFF
    & .oa-button-delete
      background none
      border 1px solid #0079FE
      color #0079FE
</style>
