var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-createCaseInfo" },
        [
          _c("div", { staticClass: "applicant-user" }, [
            _c("div", { staticClass: "user" }, [
              _c("div", { staticClass: "content-header" }, [
                _vm._v(_vm._s(_vm.sliceName(this.nickname))),
              ]),
              _c("span", [_vm._v(_vm._s(this.nickname))]),
            ]),
            _c("div", [_vm._v(_vm._s(this.case.createTimeText))]),
            _c("div", { staticClass: "status" }, [
              _vm._v(_vm._s(_vm.caseStatusNameFmt(this.case.progress))),
            ]),
          ]),
          _c("div", { staticClass: "oa-createCaseInfo-line" }),
          _c("div", { staticClass: "case" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(this.case.caseName)),
            ]),
            _c("div", { staticClass: "caseNo" }, [
              _vm._v(_vm._s(this.case.caseNo)),
            ]),
            _c("div", { staticClass: "accept-time" }, [
              _c("div", { staticClass: "accept-time-title" }, [
                _vm._v("收案时间"),
              ]),
              _c("div", { staticClass: "accept-time-content" }, [
                _vm._v(_vm._s(this.case.useTime)),
              ]),
            ]),
            _c("div", { staticClass: "accept-time" }, [
              _c("div", { staticClass: "accept-time-title" }, [
                _vm._v("诉讼标的"),
              ]),
              _c("div", { staticClass: "accept-time-content" }, [_vm._v("无")]),
            ]),
          ]),
          _c("div", { staticClass: "oa-createCaseInfo-line" }),
          _c(
            "div",
            { staticClass: "oa-approval" },
            [
              _c(
                "div",
                {
                  staticClass: "oa-approval-title",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("审批记录")]
              ),
              _c("approval-record", {
                ref: "approvalRecord",
                attrs: { typeId: Number(this.id), type: "LEGAL_CASE_ADD" },
                on: { nickname: _vm.getnickname },
              }),
            ],
            1
          ),
          _vm.operateBtnShow
            ? _c("operate-btn", {
                attrs: {
                  isAdd: 1,
                  progress: this.case.progress,
                  approveRecordUsers: this.case.approveRecordUsers,
                },
                on: { save: _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }